import { createTheme } from "@material-ui/core/styles";

export const surCorTheme = createTheme({
  palette: {
    primary: {
      main: "#034EA2",
    },
    secondary: {
      main: "#000000",
    },
    error: {
      main: "#FF0F0F",
    },
  },
  typography: {
    fontFamily: "SamsungOne",
  }
});
