import React from "react";

export const ProductSlider: React.FC<{ children: any }> = ({ children }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gridGap: "3.125rem",
        overflowX: "scroll",
        maxWidth: "78.125rem",
      }}
    >
      {children}
    </div>
  );
};
