import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
import { auth } from "../firebase-config";
import { SignUpFormValues, LoginFormValues } from "../utils";
import { createUserInDB } from "./firebase_fetch";

export const createUser = async (values: SignUpFormValues) => {
  try {
    await createUserWithEmailAndPassword(auth, values.email, values.password).then(cred => {
      return createUserInDB(cred.user.uid, values);
    });
  } catch (error: any) {
    console.log(error.message);
  }
};

export const signIn = async (values: LoginFormValues) => {
  try {
    await signInWithEmailAndPassword(auth, values.email, values.password);
  } catch (error: any) {
    console.log(error.message);
  }
};

export const endSession = async () => {
  await signOut(auth);
};
