import { FC, useEffect } from 'react';

import { Button, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

import { InventoryTable } from '../components/InventoryTable';
import { AddToInventoryModal } from '../components/modals/AddToInventoryModal';
import { surCorTheme } from '../styles';
import { useModal } from '../components/context/ModalContext';

export const InventoryPage: FC<{}> = () => {
  const { openModal } = useModal();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const openAddItemToInventoryModal = () => {
    openModal(<AddToInventoryModal />);
  }

  return (
    <div style={{
        marginTop: "9.375rem",
        padding: "0 5.25rem",
        height: '70vh'
      }}
    >
      <Typography
        sx={{
          color: surCorTheme.palette.primary.main,
          fontFamily: 'SamsungOne',
          fontSize: "2.25rem",
          fontWeight: 700,
        }}
        variant={"h1"}
      >
        Inventario
      </Typography>
      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '1rem 0' }}>
        <TextField
          id="inventory-search-input"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Buscar en Inventario"}
          size={"small"}
          sx={{
            root: { width: "18.75rem" },
            fieldset: {border: '0.063rem #000 solid'}
          }}
          variant="outlined"
        />
        <Button
          onClick={openAddItemToInventoryModal}
          sx={{
            backgroundColor: surCorTheme.palette.primary.main,
            fontFamily: "SamsungOne",
            fontSize: "1rem",
            fontWeight: 600,
            textTransform: "initial",
            "&:hover": {
              backgroundColor: "#a1a1a1",
            },
          }}
          startIcon={<AddIcon />}
          variant={"contained"}
        >
          Añadir a inventario
        </Button>
      </div>
      <Divider variant='fullWidth' />
      <InventoryTable />
    </div>
  );
};
