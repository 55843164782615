import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import global_es from '../translations/es/global.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      es: {
        global: global_es
      }
    },
    lng: "es",
    interpolation: { escapeValue: false }
  });

export default i18n;
