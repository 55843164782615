import { FeatureOption, SamsungProduct, TechSpecOption } from ".";

export const numberWithCommas = (quantity: number): string => {
  return quantity
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const createProductId = (product: SamsungProduct): string => {
  const formattedSeries = product.series.replace(/\s+/g, "");
  const formattedModel = product.model.replace(/\s+/g, "");
  console.log(formattedSeries);
  return `${formattedSeries}${formattedModel}`;
};

export const getCartTotalPrice = (collection: Array<any>) => {
  return collection.reduce(
    (sum, current) => sum + current.unitPrice * current.quantity,
    0
  );
};

export const formatFeatureOption = (option: FeatureOption) => {
  return `${option.name} - ${option.value}`;
};

export const formatTechSpecOption = (option: TechSpecOption) => {
  return `${option.name} - ${option.description}`;
};
