import { ModalActionTypes, ModalState } from "../../utils";

export const modalReducer = (state: ModalState, action: any): ModalState => {
  switch (action.type) {
    case ModalActionTypes.OPEN_MODAL: {
      return {
        ...state,
        open: true,
        content: action.modalContent
      };
    }
    case ModalActionTypes.CLOSE_MODAL: {
        return {
          ...state,
          open: false,
          content: null
        };
      }
    default:
      throw Error("Unknown action: " + action.type);
  }
};
