import React from "react";

import LoginIcon from "@mui/icons-material/Login";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { userDrawerOptions } from "../utils";
import { styles } from "../styles";
import { LoginForm } from "./LoginForm";
import { SignUpForm } from "./SignUpForm";

export const LogInDrawer: React.FC<{}> = () => {
  const [selectedOption, setSelectedOption] = React.useState<number>(0);

  const handleClick = (option: number) => {
    setSelectedOption(option);
  };

  return (
    <Grid
      alignItems={"flex-start"}
      columns={2}
      container
      direction={"row"}
      justifyContent={"flex-start"}
    >
      <div>
        <Typography sx={styles.listTitle}>Usuario</Typography>
        <List>
          <ListItem key={'user-drawer-option-0'} sx={{ ...styles.drawerItemContainer }}>
            <ListItemButton
              onClick={() => handleClick(0)}
              sx={{ ...styles.drawerItemButton }}
            >
              <ListItemIcon sx={{ ...styles.drawerIcon }}>
                {<LoginIcon />}
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& .MuiTypography-root": { ...styles.drawerOptionText },
                }}
                primary={userDrawerOptions[0].text}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key={'user-drawer-option-1'} sx={{ ...styles.drawerItemContainer }}>
            <ListItemButton
              onClick={() => handleClick(1)}
              sx={{ ...styles.drawerItemButton }}
            >
              <ListItemIcon sx={{ ...styles.drawerIcon }}>
                {<PersonAddAlt1Icon />}
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& .MuiTypography-root": { ...styles.drawerOptionText },
                }}
                primary={userDrawerOptions[1].text}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
      {selectedOption ? <SignUpForm /> : <LoginForm />}
    </Grid>
  );
};
