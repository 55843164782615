import React from "react";

import FaceIcon from "@mui/icons-material/Face";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HistoryIcon from "@mui/icons-material/History";
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutIcon from "@mui/icons-material/Logout";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { styles } from "../styles";
import { useAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";

export const UserDrawer: React.FC<{}> = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleClick = () => {
    signOut();
    navigate("/");
  };

  const goToClick = () => {
    navigate('/inventory');
  };

  return (
    <Grid
      alignItems={"flex-start"}
      columns={2}
      container
      direction={"row"}
      justifyContent={"flex-start"}
    >
      <div>
        <Typography sx={styles.listTitle}>Usuario</Typography>
        <List>
          <ListItem
            key={"user-drawer-option-0"}
            sx={{ ...styles.drawerItemContainer }}
          >
            <ListItemButton
              onClick={handleClick}
              sx={{ ...styles.drawerItemButton }}
            >
              <ListItemIcon sx={{ ...styles.drawerIcon }}>
                {<LogoutIcon />}
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& .MuiTypography-root": { ...styles.drawerOptionText },
                }}
                primary={"Cerrar sesión"}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          padding: "0 0 5rem 10.5rem",
        }}
      >
        <div style={{ marginBottom: "1.5rem" }}>
          <Typography sx={{ fontWeight: "600" }} variant="h3">
            {`Hola, ${user?.firstName} ${user?.lastName}`}
          </Typography>
        </div>
        <div>
          <List>
            {user?.role === "admin" ? (
              <>
                <ListItem key={0}>
                  <ListItemButton onClick={goToClick} sx={{ ...styles.drawerItemButton }}>
                    <ListItemIcon sx={{ ...styles.drawerIcon }}>
                      {<InventoryIcon />}
                    </ListItemIcon>
                    <ListItemText primary={"Inventario"} />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <>
                <ListItem key={0}>
                  <ListItemButton sx={{ ...styles.drawerItemButton }}>
                    <ListItemIcon sx={{ ...styles.drawerIcon }}>
                      {<FaceIcon />}
                    </ListItemIcon>
                    <ListItemText primary={"Perfil"} />
                  </ListItemButton>
                </ListItem>

                <ListItem key={1}>
                  <ListItemButton sx={{ ...styles.drawerItemButton }}>
                    <ListItemIcon sx={{ ...styles.drawerIcon }}>
                      {<HistoryIcon />}
                    </ListItemIcon>
                    <ListItemText primary={"Historial de pedidos"} />
                  </ListItemButton>
                </ListItem>

                <ListItem key={2}>
                  <ListItemButton sx={{ ...styles.drawerItemButton }}>
                    <ListItemIcon sx={{ ...styles.drawerIcon }}>
                      {<FavoriteIcon />}
                    </ListItemIcon>
                    <ListItemText primary={"Favoritos"} />
                  </ListItemButton>
                </ListItem>
              </>
            )}
          </List>
        </div>
      </div>
    </Grid>
  );
};
