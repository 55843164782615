import React from "react";
import FacebookLogin from "react-facebook-login";

import FacebookIcon from "@mui/icons-material/Facebook";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import GoogleIcon from "@mui/icons-material/Google";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  OutlinedInput,
} from "@mui/material";

import { useAuth } from "../components/context/AuthContext";
import { LoginFormValues } from "../utils";
import { surCorTheme } from "../styles";
import { signIn } from "../api";
import { useNavigate } from "react-router-dom";

export const LoginForm: React.FC<{}> = () => {
  const [login, setLogin] = React.useState(false);
  const [data, setData] = React.useState({});
  const [values, setValues] = React.useState<LoginFormValues>({
    email: "",
    password: "",
  });
  const [togglePassword, setToggglePassword] = React.useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  console.log(auth);

  const responseFacebook = (response: any) => {
    setData(response);
    console.log(data)
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
    console.log(login);
  };

  const handleChange = (prop: keyof LoginFormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    signIn(values);
    navigate('/');
  };

  const handleToggleClick = () => setToggglePassword(!togglePassword);

  return (
    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        padding: "0 0 5rem 10.5rem",
      }}
    >
        <div>
          <div style={{ marginBottom: "1.5rem" }}>
            <Typography sx={{ fontWeight: "600" }} variant="h3">
              Iniciar sesión
            </Typography>
            <Typography>Inicia sesión para seguir comprando</Typography>
          </div>
          <div
            style={{
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              minWidth: "56rem",
            }}
          >
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: '14rem'
              }}
            >
              <FormControl sx={{ width: "25ch" }} variant="outlined">
                <InputLabel>Correo electrónico</InputLabel>
                <OutlinedInput
                  autoFocus={true}
                  id="mail-input"
                  value={values.email}
                  label={"Correo electrónico"}
                  onChange={handleChange("email")}
                  aria-describedby="outlined-weight-helper-text"
                  sx={{
                    width: "21.875rem",
                  }}
                  inputProps={{
                    "aria-label": "mail",
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: "25ch" }} variant="outlined">
                <InputLabel>Contraseña</InputLabel>
                <OutlinedInput
                  id="password-input"
                  type={togglePassword ? "text" : "password"}
                  label={"Contraseña"}
                  value={values.password}
                  onChange={handleChange("password")}
                  sx={{
                    width: "21.875rem",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleClick}
                        edge="end"
                      >
                        {togglePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: surCorTheme.palette.secondary.main,
                    fontFamily: "SamsungOne",
                    fontSize: "1rem",
                    fontWeight: 600,
                    padding: "0.813rem",
                    textTransform: "capitalize",
                    width: "21.875rem",
                    "&:hover": {
                      backgroundColor: "#a1a1a1",
                    },
                  }}
                  onClick={handleSubmit}
                  variant={"contained"}
                >
                  Entrar
                </Button>
              </div>
            </div>
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                height: '14rem'
              }}
            >
              <Typography>O inicia sesión por medio de</Typography>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridColumnGap: "1em",
                  height: "auto",
                  margin: "2.25rem 0",
                }}
              >
                <FacebookLogin
                  appId="6207365449290401"
                  autoLoad={false}
                  callback={responseFacebook}
                  cssClass="my-facebook-button-class"
                  fields="name,email,picture"
                  icon={<FacebookIcon />}
                  scope="public_profile,user_friends"
                  textButton={"Facebook"}
                />
                <Button
                  sx={{
                    backgroundColor: "#DB4437",
                    fontFamily: "SamsungOne",
                    fontSize: "1rem",
                    fontWeight: 600,
                    padding: "0.813rem",
                    textTransform: "capitalize",
                    width: "10.75rem",
                    "&:hover": {
                      backgroundColor: "#EE968F",
                    },
                  }}
                  startIcon={<GoogleIcon />}
                  variant={"contained"}
                >
                  Google
                </Button>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};
