import { FC } from "react";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { surCorTheme } from "../styles";
import PageSection from "../components/PageSection";

const PageContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  flexGrow: "2",
  minHeight: "59.35vh",
  justifyContent: "flex-start",
  marginTop: "7.938rem",
  position: "relative",
  width: "100%",
});

const LegendContainer = styled("div")({
  backgroundColor: "#d04949",
  marginBottom: "1rem",
  padding: "1.5rem 5vw",
  width: "100vw",
});

export const CheckoutPageCancel: FC<{}> = () => {
  const { t } = useTranslation("global");

  return (
    <PageContainer>
      <LegendContainer>
        <Typography
          sx={{
            color: "#fff",
            fontFamily: surCorTheme.typography.fontFamily,
            fontSize: "3rem",
            fontWeight: 700,
          }}
        >
          {t("translations.errorTransaction")}
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontFamily: surCorTheme.typography.fontFamily,
            fontSize: "1rem",
            fontWeight: 400,
          }}
        >
          {t("translations.purchaseFailedMessage")}
        </Typography>
      </LegendContainer>

      <PageSection title={t("translations.orderSummary")}>
      </PageSection>
    </PageContainer>
  );
};
