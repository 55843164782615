import { createContext, FC, useCallback, useContext, useReducer } from "react";

import { ModalActionTypes, ModalContextType, ModalState } from "../../utils";
import { modalReducer } from "./ModalReducer";

const initialState: ModalState = {
  open: false,
  content: null
};

const initialModalContext: ModalContextType = {
  modalState: initialState,
  openModal: (content: any) => {},
  closeModal: () => {},
};

export const ModalContext = createContext(initialModalContext);

export const useModal = () => {
  const modal = useContext(ModalContext);
  if (!modal) throw new Error("No Modal provider");
  return modal;
};

export const ModalProvider: FC<{ children: any }> = ({ children }) => {
  const [ modalState, dispatch ] = useReducer(modalReducer, initialState);

  const openModal = useCallback((content: any) => {
    dispatch({ type: ModalActionTypes.OPEN_MODAL, modalContent: content });
  }, []);
  
  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.CLOSE_MODAL  });
  }, []);

  return (
    <ModalContext.Provider value={{ modalState, openModal, closeModal }}>
      { modalState.content }
      { children }
    </ModalContext.Provider>
  );
};
