import { FC } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useModal } from '../context/ModalContext';
import { SamsungOrderItem } from '../../utils';
import { surCorTheme } from '../../styles';
import { useGlobal } from '../context/GlobalContext';

interface DeleteProductModalProps {
  item: SamsungOrderItem;
}

export const DeleteProductModal: FC<DeleteProductModalProps> = ({ item }) => {
  const { closeModal, modalState } = useModal();
  const { deleteProductFromCart } = useGlobal();

  return (
    <Dialog open={modalState.open} onClose={closeModal}>
      <DialogTitle>
        <Typography>Eliminar producto</Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>¿Estás seguro que quieres borrar este producto de tu carrito?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            backgroundColor: surCorTheme.palette.error.main,
            fontFamily: "SamsungOne",
            fontSize: "1rem",
            fontWeight: 600,
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#a1a1a1",
            },
          }}
          onClick={() => {
            deleteProductFromCart(item);
            closeModal();
          }}
          variant={"contained"}
        >
          Borrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};