import { ReactElement } from "react";
import { SamsungProduct } from "./props";

export enum ActionTypes {
  STARTUP,
  CHANGE_LANGUAGE,
  ADD_PRODUCT_TO_CART,
  CHANGE_PRODUCT_QUANTITY,
  DELETE_PRODUCT,
  EMPTY_CART
}

export enum ModalActionTypes {
  OPEN_MODAL,
  CLOSE_MODAL
}

/**
 * @type DrawerOption
 */
export type DrawerOption = {
  key: number;
  link?: () => void;
  action?: () => any;
  icon?: ReactElement;
  text: string;
}

export type LinkImage = {
  altText: string;
  link: string;
  src: string;
};

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface SignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface SubCategory {
  name: string;
  series: string[];
}

export interface Category {
  name: string;
  drawerImageUrl: string;
  subcategories: any;
  value: string;
}

export interface User {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  favorites?: SamsungProduct[];
  history?: SamsungProduct[];
}

export type SurCorAuthContextType = {
  logIn: (values: LoginFormValues) => void;
  signUp: (values: SignUpFormValues) => void;
  signOut: () => void;
  user: User | null;
};

export type CreateInventoryBodyType = {
  name: string;
  description: string;
  reference: string;
  price: number;
  category: { id: number },
  inventory: {
    unit: string;
    unitCost: number,
    negativeSale: boolean,
    warehouses: { id: number, initialQuantity: number, minQuantity: number, maxQuantity: number }[];
  },
  tax: 2,
  type: string,
  customFields: [{id: number, value: string}],
  subitems: [{item: {id: number}, quantity: number}],
  kitWarehouse: {id: number},
  itemCategory: {id: number},
  variantAttributes: [{id: number, options: [{id: number}]}],
  accounting: {inventory: number, inventariablePurchase: number}
};

export interface SamsungOrderItem {
  id: string;
  name: string;
  quantity: number;
  thumbnail: string;
  unitPrice: number;
}

export interface SamsungOrder {
  totalPrice: number;
  items: SamsungOrderItem[];
  orderId: string;
}


export interface GlobalState {
  cart: SamsungOrder;
  featuredProds: SamsungProduct[];
  sliderImages: { urls: [] };
  weekSales: SamsungProduct[];
}

export interface GlobalContextType {
  state: GlobalState;
  addProductToCart: (product: SamsungProduct) => void;
  changeProductQuantity: (product: SamsungOrderItem, quantity: number) => void;
  deleteProductFromCart: (product: SamsungOrderItem) => void;
  emptyCart: () => void;
  changeLanguage: (lang: string) => void;
  setSnack: React.Dispatch<React.SetStateAction<{
    message: string;
    severity: string;
    open: boolean;
  }>>;
}

export interface ModalState {
  open: boolean;
  content: ReactElement | null;
}

export interface ModalContextType {
  modalState: ModalState;
  openModal: (content: any) => void;
  closeModal: () => void;
}

export interface FeatureOption {
  name: string;
  iconName: string;
  value: string;
}

export interface TechSpecOption {
  name: string;
  description: string;
}

export interface InventoryItemForm {
  imageUrls: any;
  id: string;
  name: string;
  category: string;
  model: string;
  series: string;
  subcategory: string;
  price: number;
  features: any;
  techSpecs: any;
  thumbnail: string;
}
