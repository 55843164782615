import { FC } from "react";

import { surCorTheme } from "../styles";
import { numberWithCommas, SamsungOrderItem } from "../utils";
import {
  Card,
  CardContent,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useGlobal } from "./context/GlobalContext";
import { useModal } from "./context/ModalContext";
import { DeleteProductModal } from "./modals/DeleteProductModal";

const ButtonsContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const MainCardContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
});

export const LongProductSummary: FC<{ item: SamsungOrderItem }> = ({
  item,
}) => {
  const { changeProductQuantity } = useGlobal();
  const { openModal } = useModal();

  const handleQuantityDecrease = () => {
    changeProductQuantity(item, item.quantity - 1);
  };

  const handleQuantityIncrease = () => {
    changeProductQuantity(item, item.quantity + 1);
  };

  const deleteProduct = (item: SamsungOrderItem) => {
    openModal(<DeleteProductModal item={item} />);
  };

  return (
    <Card
      sx={{
        alignItems: "flex-start",
        border: `0.063rem ${surCorTheme.palette.primary.main} solid`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "1.125rem",
        width: "100%",
      }}
    >
      <MainCardContainer>
        <img
          alt="product-img"
          height="150px"
          src={item.thumbnail}
          style={{ backgroundSize: "contain", objectFit: "contain" }}
          width="150px"
        />
        <CardContent sx={{ padding: 0 }}>
          <div style={{ marginBottom: "1rem" }}>
            <Typography
              sx={{
                color: surCorTheme.palette.primary.main,
                fontSize: "1.125rem",
                fontWeight: 600,
              }}
            >
              {item.name}
            </Typography>
            <Typography
              sx={{
                color: surCorTheme.palette.secondary.main,
                fontSize: "1.125rem",
                fontWeight: 600,
              }}
            >
              {`$ ${numberWithCommas(item.unitPrice)}`}
            </Typography>
          </div>
          <ButtonsContainer>
            <IconButton onClick={() => handleQuantityDecrease()}>
              <RemoveCircleOutlineIcon sx={{ color: surCorTheme.palette.primary.main }} />
            </IconButton>
            <FormControl>
              <TextField
                id="quantity"
                label={"Cantidad"}
                type="text"
                size={"small"}
                value={item.quantity}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  root: { width: "7.5rem" },
                  fieldset: { border: "0.063rem #000 solid" },
                }}
              />
            </FormControl>
            <IconButton onClick={() => handleQuantityIncrease()}>
              <AddCircleOutlineIcon sx={{ color: surCorTheme.palette.primary.main }} />
            </IconButton>
          </ButtonsContainer>
        </CardContent>
      </MainCardContainer>
      <div>
        <IconButton onClick={() => deleteProduct(item)}>
          <DeleteIcon sx={{ color: surCorTheme.palette.error.main }} />
        </IconButton>
      </div>
    </Card>
  );
};
