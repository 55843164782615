import { Backdrop, CircularProgress } from "@mui/material";
import { FC } from "react";

interface LoaderProps {
  open: boolean;
  handleBackdropClose: () => void;
}

export const Loader: FC<LoaderProps> = ({ open, handleBackdropClose }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        height: "100vh",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleBackdropClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
