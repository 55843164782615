import React from "react";

import { ReadingSection } from "../components/ReadingSection";
import { WhoWeAre, OurMission, OurVision } from "../utils";

export const WhoAreWePage: React.FunctionComponent<{}> = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      style={{
        height: "auto",
        marginTop: "9.375rem",
        position: "relative",
        width: "100%",
      }}
    >
      <ReadingSection
        title={WhoWeAre.title}
        content={WhoWeAre.content}
      />
      <ReadingSection
        title={OurMission.title}
        content={OurMission.content}
        side
      />
      <ReadingSection
        title={OurVision.title}
        content={OurVision.content}
      />
    </div>
  );
};
