import React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { styles } from "../styles";

export const DrawerListItem: React.FC<{
  icon?: JSX.Element;
  link?: () => void;
  text: string;
}> = ({
  icon,
  link,
  text
}) => {
  return (
    <ListItem
      sx={{ ...styles.drawerItemContainer}}
    >
      <ListItemButton
        onClick={link}
        sx={{ ...styles.drawerItemButton }}
      >
        <ListItemIcon sx={{ ...styles.drawerIcon }}>{icon}</ListItemIcon>
        <ListItemText
          sx={{
            "& .MuiTypography-root": { ...styles.drawerOptionText }
          }}
          primary={text}
        />
      </ListItemButton>
    </ListItem>
  );
};
