import { FC, ChangeEvent, useState } from "react";
import { useNavigate } from 'react-router-dom';

import {
  Button,
  FormControl,
  InputLabel,
  Typography,
  OutlinedInput,
} from "@mui/material";

import { useAuth } from "./context/AuthContext"; 
import { SignUpFormValues } from "../utils";
import { surCorTheme } from "../styles";

export const SignUpForm: FC<{}> = () => {
  const [values, setValues] = useState<SignUpFormValues>({
    firstName: '',
    lastName: '',
    email: "",
    password: ""
  });

  const { signUp } = useAuth();
  const navigate = useNavigate();

  const handleChange = (prop: keyof SignUpFormValues) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    signUp(values);
    navigate('/');
  };

  return (
    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        padding: "0 0 5rem 10.5rem",
      }}
    >
      <div>
        <div style={{ marginBottom: "1.5rem" }}>
          <Typography sx={{ fontWeight: "600" }} variant="h3">
            Crear tu cuenta
          </Typography>
          <Typography>Registrate con nosotros, y comienza a mejorar tu hogar</Typography>
        </div>
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            minWidth: "56rem",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              columnGap: "0.15rem",
              rowGap: '0.5rem',
              minWidth: "56rem",
            }}
          >
            <FormControl sx={{ width: "25ch" }} variant="outlined">
              <InputLabel>Nombre(s)</InputLabel>
              <OutlinedInput
                autoFocus={true}
                id="firstName-input"
                value={values.firstName}
                label={"Nombre(s)"}
                onChange={handleChange("firstName")}
                aria-describedby="outlined-weight-helper-text"
                sx={{
                  width: "21.875rem",
                }}
                inputProps={{
                  "aria-label": "firstname",
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "25ch" }} variant="outlined">
              <InputLabel>Correo electrónico</InputLabel>
              <OutlinedInput
                id="email-input"
                value={values.email}
                label={"Correo electrónico"}
                onChange={handleChange("email")}
                aria-describedby="outlined-weight-helper-text"
                sx={{
                  width: "21.875rem",
                }}
                inputProps={{
                  "aria-label": "password",
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "25ch" }} variant="outlined">
              <InputLabel>Apellido</InputLabel>
              <OutlinedInput
                id="lastname-input"
                value={values.lastName}
                label={"Apellido"}
                onChange={handleChange("lastName")}
                aria-describedby="outlined-weight-helper-text"
                sx={{
                  width: "21.875rem",
                }}
                inputProps={{
                  "aria-label": "lastname",
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "25ch" }} variant="outlined">
              <InputLabel>Contraseña</InputLabel>
              <OutlinedInput
                id="password-input"
                value={values.password}
                label={"Contraseña"}
                onChange={handleChange("password")}
                aria-describedby="outlined-weight-helper-text"
                type={'password'}
                sx={{
                  width: "21.875rem",
                }}
                inputProps={{
                  "aria-label": "password",
                }}
              />
            </FormControl>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "1.5rem",
              }}
            >
              <Button
                onClick={handleSubmit}
                sx={{
                  backgroundColor: surCorTheme.palette.secondary.main,
                  fontFamily: "SamsungOne",
                  fontSize: "1rem",
                  fontWeight: 600,
                  padding: "0.813rem",
                  textTransform: "none",
                  width: "21.875rem",
                  "&:hover": {
                    backgroundColor: "#a1a1a1",
                  },
                }}
                variant={"contained"}
              >
                Crear cuenta
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
