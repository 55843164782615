import { surCorTheme } from "./theme";

export const styles = {
  productPrice: {
    fontFamily: `${surCorTheme.typography.fontFamily} !important`,
    fontWeight: 700,
    fontSize: '4.5rem',
  },
  postContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: '1.5rem',
    width: '78.125rem',
  },
  postBodyContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
  },
  postImageSquare: {
    height: "24rem",
    width: "24rem",
  },
  postTitle: {
    color: `${surCorTheme.palette.secondary.main} !important`,
    fontFamily: `${surCorTheme.typography.fontFamily} !important`,
    fontSize: '1.5rem !important',
    marginBottom: "0.75rem !important"
  },
  postBody: {
    color: `${surCorTheme.palette.secondary.main} !important`,
    fontFamily: `${surCorTheme.typography.fontFamily} !important`,
    fontSize: "0.875rem !important",
    fontWeight: `${600} !important`,
  },
  drawerItemContainer: {
    width: "14rem",
  },
  drawerItemButton: {
    "&:hover": {
      color: surCorTheme.palette.primary.main,
    },
  },
  drawerIcon: {
    color: surCorTheme.palette.primary.main,
  },
  listTitle: {
    fontFamily: surCorTheme.typography.fontFamily,
    fontWeight: 600!,
  },
  drawerSideTitle: {
    fontFamily: `${surCorTheme.typography.fontFamily} !important`,
    fontSize: "1.5rem !important",
    fontWeight: `${600} !important`,
  },
  drawerSideText: {
    fontFamily: `${surCorTheme.typography.fontFamily} !important`,
    fonrWeight: `${400} !important`,
  },
  drawerOptionText: {
    fontFamily: surCorTheme.typography.fontFamily,
    fontWeight: 400,
  },
  appBar: {
    boxShadow: "none",
    height: "4.25rem",
  },
  buttonsContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    width: "9rem",
  },
  mainContainer: {
    alignItems: "center",
    display: "inline-grid",
    gridTemplateColumns: 'max-content max-content max-content max-content auto',
    gap: '0.75rem',
    justifyItems: 'center',
    width: "48rem",
  },
  mainNavBar: {
    backgroundColor: "#ffffff",
    boxShadow: "none",
    margin: "0px",
  },
  sectionTitle: {
    color: `${surCorTheme.palette.primary.main} !important`,
    fontFamily: `${surCorTheme.typography.fontFamily} !important`,
    fontSize: "2.5rem",
    fontWeight: `${600} !important`,
    marginBottom: '1.5rem'
  },
  bannerImage: {
    display: "flex",
  },
  homeLogo: {
    width: "50.75rem",
  },
  searchBarPaper: {
    alignItems: "center",
    border: "0.063rem solid black",
    boxShadow: "none",
    display: "flex",
    padding: "0.125rem 0.25rem",
    width: 400,
  },
  searchBarInput: {
    marginLeft: "0.5rem",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchBarDivider: {
    height: 28,
    margin: 4,
  },
  storeButton: {
    backgroundColor: "#19a8ff",
    "&:hover": {
      background: "#158fda",
    },
    border: "0.063rem solid #000000",
    color: surCorTheme.palette.secondary.main,
    fontSize: "1rem",
    padding: "0.5rem 1rem",
  },
  rechargeButton: {
    backgroundColor: "#fffa1b",
    "&:hover": {
      background: "#c7c300",
    },
    border: "0.063rem solid #000000",
    color: surCorTheme.palette.secondary.main,
    fontSize: "1rem",
    padding: "0.5rem 1rem",
  },
  printButton: {
    backgroundColor: "#ff35bf",
    "&:hover": {
      background: "#bb288d",
    },
    border: "0.063rem solid #000000",
    color: surCorTheme.palette.secondary.main,
    fontSize: "1rem",
    padding: "0.5rem 1rem",
  },
  facebookIcon: { color: " #3b5998", marginRight: "0.75rem" },
  mailIcon: { color: "darkgray", marginRight: "0.75rem" },
  phoneIcon: { color: "blueviolet", marginRight: "0.75rem" },
  whatsappIcon: { color: "#25D366", marginRight: "0.75rem" },
  contactInfoContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "0.75rem",
  },

  mainBodyContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as "column",
    height: "100%",
    position: "relative" as "relative",
    top: "8.75rem",
    width: "100%",
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    width: "22%",
  },
  colorsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "3.75rem",
  },
  blueContainer: {
    backgroundColor: "#19a8ff",
    height: "100%",
    width: "33%",
  },
  drawerOptionsContainer: {
    overflowY: "auto",
    width: "100%",
  },
  contentContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as "column",
    flexGrow: 1,
    padding: "1.5rem",
  },
  contentTitle: {
    color: surCorTheme.palette.primary.main,
    fontFamily: surCorTheme.typography.fontFamily,
    fontSize: "48px",
    fontWeight: 800,
    marginBottom: "48px",
    textAlign: 'right' as 'right'
  },
  contentText: {
    fontFamily: surCorTheme.typography.fontFamily,
    fontSize: "1rem",
    fontWeight: 400,
  },
  bannerContainer: {
    borderRadius: "14px",
    height: "12.5rem",
    width: "12.5rem",
  },
  footerBarContainer: {
    bottom: 0,
    left: 0,
    flexGrow: 1,
    height: "16.5rem",
    marginTop: "auto",
    width: "100%",
  },
  footerBar: {
    alignItems: "center",
    backgroundColor: surCorTheme.palette.secondary.main,
    color: "#ffffff",
    display: "flex",
    flexDirection: "column" as "column",
    height: "100%",
    justifyContent: "space-between",
    padding: "1.5rem 0",
  },
  footerPhrasing: {
    fontStyle: "italic",
    fontWeight: 600,
  },
  copyright: {
    fontWeight: 400,
  },
};
