import { Typography } from "@mui/material";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import { FC } from "react";
import { surCorTheme } from "../styles";

export const EmptyCartView: FC<{}> = () => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "1fr",
        height: "50vh",
        justifyItems: "center",
        marginBottom: "4.75rem",
        marginTop: "9.375rem",
        position: "relative",
      }}
    >
      <ProductionQuantityLimitsIcon
        sx={{ color: surCorTheme.palette.primary.main, fontSize: "10rem" }}
      />
      <Typography
        sx={{
          color: surCorTheme.palette.primary.main,
          fontSize: "2.25rem",
          fontWeight: 700,
        }}
        variant={"h1"}
      >
        No hay productos en tu carrito
      </Typography>
    </div>
  );
};
