import { onAuthStateChanged } from "@firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { createUser, endSession, getUserByUID, signIn } from "../../api";
import { auth } from "../../firebase-config";
import { SignUpFormValues, LoginFormValues, SurCorAuthContextType } from "../../utils";

export const AuthContext = createContext<SurCorAuthContextType>({
  signUp: () => { return },
  signOut: () => { return },
  logIn: () => { return },
  user: null
});

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) throw new Error("No Auth provider");
  return auth;
};

export const AuthProvider: React.FC<{ children: any }> = ({ children }) => {
  const [user, setUser] = useState<any | null>(null);
  const signUp = (values: SignUpFormValues) => {
    createUser(values);
  };

  const logIn = (values: LoginFormValues) => {
    signIn(values);
  }

  const signOut = () => endSession();

  const getUserData = () => {
    console.log('Auth Provider has loaded succesfully');
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userData = await getUserByUID(currentUser.uid);
        setUser(userData);
      }
    });
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (<AuthContext.Provider value={{ signUp, signOut, logIn, user }}>{children}</AuthContext.Provider>);
};
