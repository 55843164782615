import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { surCorTheme } from "../styles";
import { SamsungProduct, numberWithCommas, createProductId } from "../utils";
import { useNavigate } from "react-router-dom";

export const ProductSummary: React.FC<{ product: SamsungProduct }> = ({
  product,
}) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    navigate(`product/${createProductId(product)}`);
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        alignItems: "center",
        border: `0.063rem ${surCorTheme.palette.primary.main} solid`,
        display: "flex",
        flexDirection: "column",
        height: "21.188rem",
        padding: "1.125rem",
        width: "13.438rem",
        "&:hover": {
          boxShadow: `inset 0 1px 16px ${surCorTheme.palette.primary.main}`,
          cursor: 'pointer'
        },
      }}
    >
      <CardMedia
        alt="product-img"
        component="img"
        height="200"
        image={product.thumbnail}
        sx={{ objectFit: 'contain' }}
        width="200"
      />
      <CardContent sx={{ padding: 0}}>
        <Typography
          sx={{
            color: surCorTheme.palette.primary.main,
            fontSize: "1.125rem",
            fontWeight: 600,
          }}
        >
          {product.name}
        </Typography>
        <Typography
          sx={{
            color: surCorTheme.palette.secondary.main,
            fontSize: "1.125rem",
            fontWeight: 600,
          }}
        >
          {`$ ${numberWithCommas(product.price)}`}
        </Typography>
      </CardContent>
    </Card>
  );
};
