import React from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import { DrawerListItem } from "./DrawerListItem";
import { contactDrawerOptions } from "../utils";

import { styles } from "../styles";
import { GoogleMapViewer } from "./GoogleMapViewer";

export const ContactDrawer: React.FC<{}> = () => {
  /*const handleToggle = () => toggleDrawer(!isOpen);*/

  return (
    <Grid
      alignItems={"flex-start"}
      columns={2}
      container
      direction={"row"}
      justifyContent={"flex-start"}
    >
      <div>
        <Typography sx={styles.listTitle}>Contacto</Typography>
        <List>
          <DrawerListItem
            icon={<FacebookIcon />}
            link={contactDrawerOptions[0].link}
            text={contactDrawerOptions[0].text}
          />
          <DrawerListItem
            icon={<WhatsAppIcon />}
            link={contactDrawerOptions[1].link}
            text={contactDrawerOptions[1].text}
          />
          <DrawerListItem
            icon={<InstagramIcon />}
            link={contactDrawerOptions[2].link}
            text={contactDrawerOptions[2].text}
          />
        </List>
      </div>
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          padding: "0 0 1.5rem 10.5rem",
        }}
      >
        <Typography sx={{ fontWeight: "600" }} variant="h3">
          ¡Contáctanos!
        </Typography>
        <Typography>
          Encuentranos en los enlaces del lado izquierdo o visitanos en nuestra
          sucursal
        </Typography>
          <GoogleMapViewer />
      </div>
    </Grid>
  );
};
