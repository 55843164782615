import React from "react";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { getCategoriesCollection } from "../api";
import { Category } from "../utils";
import { styles } from "../styles";

import { useTranslation } from "react-i18next";

export const AppliancesDrawer: React.FC<{}> = () => {
  const [options, setOptions] = React.useState<Category[]>([]);
  const { t } = useTranslation("global");

  React.useEffect(() => {
    getCategoriesCollection().then((data) => {
      setOptions(data);
    });
  }, [setOptions]);

  const handleClick = (category: Category) => {
  };

  return (
    <Grid
      alignItems={"flex-start"}
      columns={2}
      container
      flexWrap={"nowrap"}
      direction={"row"}
      justifyContent={"flex-start"}
    >
      <div>
        {options.map((option, index) => (
          <>
            <Typography sx={styles.listTitle}>{t(`subcategoryOptionLabels.${option.value}`)}</Typography>
            <List>
              <ListItem key={index} sx={{ ...styles.drawerItemContainer }}>
                <ListItemButton
                  onClick={() => handleClick(option)}
                  sx={{ ...styles.drawerItemButton }}
                >
                  <ListItemIcon sx={{ ...styles.drawerIcon }}></ListItemIcon>
                  <ListItemText
                    sx={{
                      "& .MuiTypography-root": { ...styles.drawerOptionText },
                    }}
                    primary={t(`seriesOptionLabels.${option.value}`)}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </>
        ))}
      </div>
    </Grid>
  );
};
