import React, { Fragment, MouseEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";

import { styles } from "../styles";

import { getLogo } from '../api/firebase_fetch';

import { AppliancesDrawer } from "./AppliancesDrawer";
import { ContactDrawer } from "./ContactDrawer";
import { LogInDrawer } from "./LoginDrawer";
import { useAuth } from "./context/AuthContext";
import { UserDrawer } from "./UserDrawer";
import { useGlobal } from "./context/GlobalContext";
import { useTranslation } from "react-i18next";

const NavigationBar: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { state } = useGlobal();
  const { t } = useTranslation('global');
  const [logo, setLogo] = useState('');
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [drawerMenu, setDrawerMenu] = useState(0);
  const [drawerContent, setDrawerContent] = useState(<Fragment />);

  useEffect(() => {
    (async () => {
        const data = await getLogo();
        setLogo(data.url);
    })();
  }, []);

  useEffect(() => {
    switch (drawerMenu) {
      case 0:
        setDrawerContent(<AppliancesDrawer />);
        break;
      case 1:
        setDrawerContent(<ContactDrawer />);
        break;
      case 2:
        setDrawerContent(user ? <UserDrawer /> : <LogInDrawer />);
        break;
      default:
        setToggleDrawer(false);
        break;
    }
  }, [drawerMenu, setDrawerContent, user]);

  const handleNavClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    menu: number
  ) => {
    event.preventDefault();
    if (!toggleDrawer) {
      setToggleDrawer(true);
    }
    setDrawerMenu(menu);
  };

  return (
    <Accordion
      expanded={toggleDrawer}
      onMouseLeave={(e) => {
        e.preventDefault();
        setToggleDrawer(false);
      }}
      sx={{
        position: "fixed" as "fixed",
        top: 0,
        width: "100%",
        zIndex: 999,
      }}
    >
      <Toolbar sx={styles.mainNavBar}>
        <Grid
          alignItems={"center"}
          columns={2}
          container
          direction={"row"}
          justifyContent={"space-between"}
        >
          <div style={styles.mainContainer}>
            <Link to={"/"}>
              <img alt={'surcor-logo'} src={logo} height={120} width={120}/>
            </Link>
            <Button onClick={(e: any) => handleNavClick(e, 0)}>{t(`categoryOptionLabels.mobile`)}</Button>
            <Button onClick={(e: any) => handleNavClick(e, 0)}>{t(`categoryOptionLabels.tv_audio`)}</Button>
            <Button onClick={(e: any) => handleNavClick(e, 0)}>{t(`categoryOptionLabels.appliances`)}</Button>
            <TextField
              fullWidth
              id="global-search-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Buscar"}
              size={"small"}
              sx={{
                root: { width: "20rem" },
                fieldset: {border: '0.063rem #000 solid'}
              }}
              variant="outlined"
            />
          </div>
          <div style={styles.buttonsContainer}>
            <IconButton onClick={(e: any) => handleNavClick(e, 1)}>
              <PhoneIcon />
            </IconButton>
            <IconButton onClick={(e: any) => handleNavClick(e, 2)}>
              <PersonIcon />
            </IconButton>
            <Badge badgeContent={state.cart.items.reduce((sum, current) => sum + current.quantity, 0)} color="primary">
              <IconButton onClick={(e) => navigate("/cart")}>
                <ShoppingCartIcon />
              </IconButton>
            </Badge>
          </div>
        </Grid>
      </Toolbar>
      <AccordionDetails
        sx={{
          padding: "0 2.625rem",
        }}
      >
        {toggleDrawer && (
          <IconButton
            aria-label="close"
            onClick={() => {
              setToggleDrawer(false);
            }}
            sx={{
              float: "right",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {drawerContent}
      </AccordionDetails>
    </Accordion>
  );
};

export default NavigationBar;
