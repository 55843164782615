import { createContext, FC, useCallback, useContext, useEffect, useReducer, useState } from "react";
import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { getFeaturedCollection, getSliderImages } from "../../api";
import { SamsungOrderItem, SamsungProduct, GlobalContextType, GlobalState, ActionTypes } from "../../utils";
import { globalReducer } from "./GlobalReducer";
import { I18nextProvider } from "react-i18next";
import i18n from "../../utils/i18next";

const initialState: GlobalState = {
  cart: { orderId: '', items: [], totalPrice: 0 },
  featuredProds: [],
  weekSales: [],
  sliderImages: { urls: [] }
};

const initialCreateContext: GlobalContextType = {
  state: initialState,
  addProductToCart: (product: SamsungProduct) => {},
  changeProductQuantity: (product: SamsungOrderItem, quantity: number) => {},
  deleteProductFromCart: (product: SamsungOrderItem) => {},
  emptyCart: () => {},
  changeLanguage: (language: string) => {},
  setSnack: () => {}
};

export const GlobalContext = createContext(initialCreateContext);

export const useGlobal = () => {
  const global = useContext(GlobalContext);
  if (!global) throw new Error("No Global provider");
  return global;
};

export const GlobalProvider: FC<{ children: any }> = ({ children }) => {
  const [ state, dispatch ] = useReducer(globalReducer, initialState);

  const [ snack, setSnack ] = useState({
    message: '',
    severity: '',
    open: false,
  });

  const loadInitialState = useCallback(async () => {
    // Obtain the URLS and featured products
    const featuredList = await getFeaturedCollection();
    const sliderImgs = await getSliderImages();

    dispatch({ type: ActionTypes.STARTUP, featuredProdList: featuredList, sliderImages: sliderImgs })

    // Set initial cart object for session storage
    localStorage.setItem('cart', JSON.stringify(state.cart));
  }, [state.cart]);

  useEffect(() => {
    loadInitialState();
  }, [loadInitialState]);

  /**
   * Adds a product to localStorage and updates the global state
   * @param product Product to add/modify from the cart
   */
  const addProductToCart = useCallback((product: SamsungProduct) => {
    dispatch({ type: ActionTypes.ADD_PRODUCT_TO_CART, newProduct: product });
    setSnack({
      message: "Producto agregado al carrito",
      severity: "success",
      open: true,
    });
  }, []);

  const changeProductQuantity = useCallback((product: SamsungOrderItem, quantity: number) => {
    if (quantity > 0)
      dispatch({ type: ActionTypes.CHANGE_PRODUCT_QUANTITY, newProduct: product, quantity: quantity });
    else
      dispatch({ type: ActionTypes.DELETE_PRODUCT, newProduct: product });
  }, []);

  const changeLanguage = (language: string) => {
    dispatch({ type: ActionTypes.CHANGE_LANGUAGE, langCode: language });
  };

  const handleSnakcbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') { return; }
    setSnack((prevSnackState: any) => ({ ...prevSnackState, open: false }));
  };

  const emptyCart = useCallback(() => {
    dispatch({ type: 'EMPTY_CART' });
    setSnack({
      message: "Se ha vaciado el carrito",
      severity: "info",
      open: true,
    });
  }, []);

  const deleteProductFromCart = useCallback((product: SamsungOrderItem) => {
    dispatch({ type: ActionTypes.DELETE_PRODUCT, newProduct: product });
    setSnack({
      message: "Producto eliminado del carrito",
      severity: "warning",
      open: true,
    });
  }, []);

  return (
    <GlobalContext.Provider value={{
      state,
      addProductToCart,
      changeProductQuantity,
      changeLanguage,
      deleteProductFromCart,
      emptyCart,
      setSnack
    }}>
      
      <I18nextProvider i18n={i18n}>
        <Snackbar open={snack.open} autoHideDuration={3000} onClose={handleSnakcbarClose}>
          <Alert elevation={6} severity={snack.severity as AlertColor} variant="filled">
            {snack.message}
          </Alert>
        </Snackbar>
        { children }
      </I18nextProvider>
    </GlobalContext.Provider>
  );
};
