import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { surCorTheme } from "../styles";
import Icon from "@mui/material/Icon";

export const FeatureDisplay: React.FC<{ feature: any }> = ({
  feature,
}) => {
  return (
    <Card
      sx={{
        alignItems: "center",
        border: `0.063rem ${surCorTheme.palette.primary.main} solid`,
        display: "flex",
        flexDirection: "column",
        height: "13.188rem",
        justifyContent: 'space-evenly',
        padding: "0 1.125rem",
        width: "11.188rem",
      }}
    >
      <CardContent
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: 'space-evenly',
        }}
      >
        <Icon 
          sx={{
            color: surCorTheme.palette.primary.main,
            fontSize: '5.5rem'
          }}
        >
          {feature.iconName}
        </Icon>
        <Typography
          sx={{
            color: surCorTheme.palette.primary.main,
            fontSize: "1.125rem",
            fontWeight: 600,
          }}
        >
          {feature.name}
        </Typography>
        <Typography
          sx={{
            color: surCorTheme.palette.primary.main,
            fontSize: "1.125rem",
            fontWeight: 400,
          }}
        >
          {feature.value}
        </Typography>
      </CardContent>
    </Card>
  );
};
