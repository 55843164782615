import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";

import "./App.css";
import FooterBar from "./components/FooterBar";
import NavigationBar from "./components/NavigationBar";
import { LandingPage, WhoAreWePage, ProductOverviewPage, CartPage, InventoryPage, ProfilePage, CheckoutPage } from "./pages";
import { surCorTheme } from "./styles";
import { app } from "./firebase-config";
import { AuthProvider } from "./components/context/AuthContext";
import { GlobalProvider } from "./components/context/GlobalContext";
import { CheckoutPageSuccess } from "./pages/CheckoutPageSuccess";
import { CheckoutPageCancel } from "./pages/CheckoutPageCancel";
import { ModalProvider } from "./components/context/ModalContext";

function App() {
  return (
    <FirebaseAppProvider firebaseApp={app}>
      <GlobalProvider>
        <AuthProvider>
          <BrowserRouter>
            <ThemeProvider theme={surCorTheme}>
              <ModalProvider>
                <CssBaseline />
                <NavigationBar />
                <Routes>
                  <Route element={<LandingPage />} path="/" />

                  <Route element={<ProductOverviewPage />} path="/product/:productId" />
                  <Route element={<ProductOverviewPage />} path="/products/search/:searchQuery" />
                  <Route element={<ProductOverviewPage />} path="/products/:query" />

                  <Route element={<CartPage />} path="/cart" />
                  <Route element={<CheckoutPage />} path="/checkout" />
                  <Route element={<CheckoutPageSuccess />} path="/checkout_success/:orderInfo" />
                  <Route element={<CheckoutPageCancel />} path="/checkout_cancel" />

                  <Route element={<ProfilePage />} path="/profile" />
                  <Route element={<ProfilePage />} path="/profile/orders" />
                  <Route element={<CartPage />} path="/profile/settings" />
                  <Route element={<InventoryPage />} path="/inventory" />

                  <Route element={<WhoAreWePage />} path="/us" />
                  <Route element={<WhoAreWePage />} path="/tos" />
                </Routes>
                <FooterBar />
              </ModalProvider>
            </ThemeProvider>
          </BrowserRouter>
        </AuthProvider>
      </GlobalProvider>
    </FirebaseAppProvider>
  );
}

export default App;

/**
 * 
            <Route element={<ProductsPage />} path="/productos/:category" />
            <Route element={<ProductDetailsPage />} path="/producto/:category/:productId" />
 */
