import React from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import data from "../json/info.json";

export const GoogleMapViewer: React.FC<{}> = () => {
  const marker = {
    position: {
      lat: data.location.lat,
      lng: data.location.lng,
    },
    label: { color: "white", text: "" },
    draggable: false,
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: data.location.lat,
    lng: data.location.lng,
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
      <GoogleMap
        center={center}
        options={{ disableDefaultUI: true, gestureHandling: 'none' }}
        mapContainerStyle={containerStyle}
        zoom={19}
      >
        <Marker
          position={marker.position}
          label={marker.label}
          draggable={marker.draggable}
        >
          <InfoWindow position={marker.position}>
            <b>
              {data.location.address}
            </b>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    </LoadScript>
  );
};
