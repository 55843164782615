import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase-config";
import { SamsungProduct, SignUpFormValues } from "../utils";

export const getLogo = async () => {
  const snapRef = await getDoc(doc(db, "imageUrls", "surcor-logo"));
  return snapRef.data() as any;
};

export const getCategoriesCollection = async () => {
  let result: any = [];
  const q = query(collection(db, "categories"));

  const qs = await getDocs(q);
  result = qs.docs.map((doc) => doc.data());
  return result;
};

export const getSubcategoriesCollection = async () => {
  let result: any = [];
  const q = query(collection(db, "subcategories"));
  
  const qs = await getDocs(q);
  result = qs.docs.map((doc) => doc.data());
  return result;
};

export const getSeriesCollection = async () => {
  let result: any = [];
  const q = query(collection(db, "series"));

  const qs = await getDocs(q);
  result = qs.docs.map((doc) => doc.data());
  return result;
};

export const getModelsCollection = async () => {
  let result: any = [];
  const q = query(collection(db, "models"));

  const qs = await getDocs(q);
  result = qs.docs.map((doc) => doc.data());
  return result;
};

export const getProductById = async (productId: string) => {
  const docRef = doc(db, "products", productId);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as SamsungProduct;
};

export const getFeaturedCollection = async () => {
  const result: any = [];
  const collectionRef = collection(db, "products");
  const q = query(collectionRef, where('featured', '==', true));
  const snap = await getDocs(q);

  snap.forEach((doc) => {
    const check = doc.data();
    result.push(check);
  });
  return result;
};

export const getThumbnailImage = (url: string) => {
  const result: any = [];
  const imageRef = ref(storage, url);
  listAll(imageRef).then((response) => {
    response.items.forEach((item) =>
      getDownloadURL(item).then((url) => result.push(url))
    );
  });

  return result;
};

export const getSliderImages = async () => {
  const snapRef = await getDoc(doc(db, "imageUrls", "slider"));
  return snapRef.data() as any;
};

export const getCloudImages = (folderUrl: string) => {
  const result: any = [];
  const imageRef = ref(storage, folderUrl);
  listAll(imageRef).then((response) => {
    response.items.forEach((item) =>
      getDownloadURL(item).then((url) => result.push(url))
    );
  });

  return result;
};

export const createUserInDB = async (uid: any, values: SignUpFormValues) => {
  await setDoc(doc(db, 'users', uid), {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email
  });
};

export const getUserByUID = async (uid: string) => {
  const ref = doc(db, 'users', uid);
  const snap = await getDoc(ref);

  return snap.data();
};

export const getInventoryItems = async () => {
  const result: any = [];
  const q = await getDocs(collection(db, "products"));
  q.forEach((doc) => {
    const check = doc.data();
    result.push(check);
  });
  return result;
};

export const getFeatureOptions = async () => {
  const result: any = [];
  const q = await getDocs(collection(db, "features"));
  q.forEach((doc) => {
    const check = doc.data();
    result.push(check);
  });
  return result;
};

export const getTechSpecsOptions = async () => {
  const result: any = [];
  const q = await getDocs(collection(db, "techSpecs"));
  q.forEach((doc) => {
    const check = doc.data();
    result.push(check);
  });
  return result;
};
