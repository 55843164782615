// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "beaming-key-359105.firebaseapp.com",
  databaseURL: "https://beaming-key-359105-default-rtdb.firebaseio.com",
  projectId: "beaming-key-359105",
  storageBucket: "beaming-key-359105.appspot.com",
  messagingSenderId: "811488579142",
  appId: "1:811488579142:web:2e0cb752fb81561b4b8447",
  measurementId: "G-9LYW9QXG4V"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);