import Typography from "@mui/material/Typography";

import React from "react";

import { PageSectionProps } from "../utils";
import { styles } from "../styles";

const PageSection: React.FunctionComponent<PageSectionProps> = ({
  children,
  title,
}: PageSectionProps) => {
  return (
    <div style={{
      height: 'auto',
      marginBottom: '4rem'
    }}>
      <Typography sx={styles.sectionTitle}>
        {title}
      </Typography>
      {children}
    </div>
  );
};

export default PageSection;
