import { FC, Fragment, useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

interface AutocompleteSelectBoxProps {
  disabled: boolean;
  field: any;
  id: string;
  label: string;
  options: any;
  optionFormat: (option: any) => string;
  onChange: (event: any, value: any) => void;
}

export const AutocompleteSelectBox: FC<AutocompleteSelectBoxProps> = ({ id, field, label, options, optionFormat, onChange, disabled }) => {
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;
  
  return (
    <Autocomplete
      defaultValue={field.value}
      disabled={disabled}
      filterSelectedOptions
      getOptionLabel={optionFormat}
      id={id}
      loading={loading}
      onChange={onChange}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      options={options.map((o: any) => o.value)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          label={label}
          margin="dense"
          sx={{
            fieldset: { border: "0.063rem #000 solid" },
          }}
        />
      )}
      value={field.value}
    />
  );
};
