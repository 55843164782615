import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { FC } from 'react';
import ImageUploading from 'react-images-uploading';

import { surCorTheme } from '../styles';
import { useTranslation } from 'react-i18next';

interface ImageUploaderProps {
  images: any;
  onChange: (...event: any[]) => void;
}

const ImageUploaderMainContainer = styled('div')({
  width: '12rem',
});

const ImageWrapper = styled('div')({
  alignItems: 'center',
  border: `0.125rem ${surCorTheme.palette.primary.main} dashed`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: '0.75rem',
  width: '100%',
});

export const ImageUploader: FC<ImageUploaderProps> = ({ images, onChange }) => {
  const { t } = useTranslation("global");
  return (
    <ImageUploaderMainContainer>
      <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
        {({ imageList, onImageUpdate, onImageRemove, onImageUpload }) => (
          <div>
            <ImageWrapper>
              {imageList.length !== 0 ? imageList.map((image, index) => (
                  <img key={`upld_${index}`} src={image['data_url']} alt="" width="150" height="180" />
              )) : (
                <>
                  <UploadFileIcon
                    sx={{
                      color: surCorTheme.palette.primary.main,
                      height: '8rem',
                      stroke: '#ffffff', 
                      strokeWidth: 1,
                      width: '8rem'
                    }}
                  />
                  <Typography
                    sx={{
                      color: surCorTheme.palette.primary.main,
                      fontFamily: 'SamsungOne',
                      fontSize: "0.75rem",
                      fontWeight: 400,
                    }}
                    variant={"h1"}
                  >
                    {t("pageContent.imageUploaderInstructions")}
                  </Typography>
                </>
              )}
            </ImageWrapper>
            <Button
              onClick={() => {
                onImageUpload();
              }}
              sx={{
                backgroundColor: surCorTheme.palette.primary.main,
                fontFamily: "SamsungOne",
                fontSize: "1rem",
                fontWeight: 600,
                marginTop: '0.375rem',
                textTransform: "initial",
                "&:hover": {
                  backgroundColor: "#a1a1a1",
                }
              }}
              variant={"contained"}
            >
              Cargar
            </Button>
          </div>
        )}
      </ImageUploading>
    </ImageUploaderMainContainer>
  );
};