import { Typography, Button } from "@mui/material";
import { styled } from '@mui/system';

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

import { useEffect, FC } from "react";
import { useGlobal } from "../components/context/GlobalContext";
import { LongProductSummary } from "../components/LongProductSummary";
import { surCorTheme } from "../styles";
import { GlobalState, SamsungOrderItem, numberWithCommas } from "../utils";
import { EmptyCartView } from "../components/EmptyCartView";

const MainContainer = styled('div')({
  alignItems: "center",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2rem",
  height: '60vh',
  justifyItems: "center",
  marginBottom: "9.375rem",
  marginTop: "9.375rem",
  padding: "0 5.25rem",
  position: "relative",
  width: "100vw",
});

const MainHeader = styled('div')({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: '80vw'
});

const ButtonContainer = styled('div')({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  height: "auto",
  width: '80vw'
});

const CartPageView: FC<{ state: GlobalState }> = ({ state }) => {
  const { emptyCart } = useGlobal();
  return (
    <MainContainer>
      <MainHeader>
        <Typography
          sx={{
            color: surCorTheme.palette.primary.main,
            fontSize: "2.25rem",
            fontWeight: 700,
          }}
          variant={"h1"}
        >
          Carrito
        </Typography>
        <Typography
          sx={{
            color: surCorTheme.palette.primary.main,
            fontSize: "2.25rem",
            fontWeight: 700,
            whiteSpace: "nowrap",
          }}
          variant={"h1"}
        >
          {`Total: $${ numberWithCommas(state.cart.totalPrice) }`}
        </Typography>
      </MainHeader>
        {state.cart.items.map((item: SamsungOrderItem) => (
          <LongProductSummary item={item} />
        ))}
      <ButtonContainer>
        <Button
          sx={{
            backgroundColor: surCorTheme.palette.secondary.main,
            fontFamily: "SamsungOne",
            fontSize: "1rem",
            fontWeight: 600,
            padding: "0.813rem",
            textTransform: "capitalize",
            width: "12.75rem",
            "&:hover": {
              backgroundColor: "#a1a1a1",
            },
          }}
          onClick={() => {
            emptyCart();
          }}
          variant={"contained"}
          startIcon={<RemoveShoppingCartIcon />}
        >
          Vaciar
        </Button>
        <Button
          sx={{
            backgroundColor: surCorTheme.palette.primary.main,
            fontFamily: "SamsungOne",
            fontSize: "1rem",
            fontWeight: 600,
            padding: "0.813rem",
            textTransform: "capitalize",
            width: "12.75rem",
            "&:hover": {
              backgroundColor: "#a1a1a1",
            },
          }}
          onClick={() => {
            return;
          }}
          variant={"contained"}
          startIcon={<AttachMoneyIcon />}
        >
          PAGAR
        </Button>
      </ButtonContainer>
    </MainContainer>
  );
};

export const CartPage: FC<{}> = () => {
  const { state } = useGlobal();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {!state.cart.items.length ? (
        <EmptyCartView />
      ) : (
        <CartPageView state={state} />
      )}
    </div>
  );
};
