import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';

import Typography from "@mui/material/Typography";
import { styles } from "../styles";

const FooterBar: React.FunctionComponent<{}> = () => {
  return (
    <footer style={styles.footerBarContainer}>
      <div style={styles.footerBar}>
        <Grid
          alignItems={"flex-start"}
          columns={2}
          container
          direction={"row"}
          justifyContent={"space-evenly"}
        >
          <div>
            <Typography sx={styles.copyright} variant="subtitle2">
              Conócenos
            </Typography>
            <ul>
              <li>
                <Link to='/nosotros' >
                  Nosotros
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <Typography sx={styles.copyright} variant="subtitle2">
              Productos y servicios
            </Typography>
            <ul>
              <li>Moviles</li>
              <li>TV y Audio</li>
              <li>Linea blanca</li>
            </ul>
          </div>
          <div>
            <Typography sx={styles.copyright} variant="subtitle2">
              Nosotros
            </Typography>
            <ul>
              <li>Términos y condiciones</li>
              <li>Soporte</li>
            </ul>
          </div>
        </Grid>
        <Typography sx={styles.copyright} variant="subtitle2">
          {`© 2020 - ${new Date().getFullYear()} Distribuidora de Tecnología SurCor`}
        </Typography>
      </div>
    </footer>
  );
};

export default FooterBar;
