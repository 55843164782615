import { FC, useEffect, useState } from "react";
import { styled } from '@mui/system';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "../components/CheckoutForm";
import { useGlobal } from "../components/context/GlobalContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY as string);

const PageContainer = styled('div')({
  marginTop: "9.375rem",
  padding: "0 5.25rem",
  height: "70vh",
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
});

export const CheckoutPage: FC<{}> = () => {
  const { state } = useGlobal();
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("http://localhost:4000/checkout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: state.cart }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, [state.cart]);

  const appearance = {
    theme: 'stripe',
  };
  
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <PageContainer>
      <Elements options={options as any} stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </PageContainer>
  );
};
