import React from "react";
import { useParams } from "react-router-dom";

import { ProductDetails } from "../components/ProductDetails";

export const ProductOverviewPage: React.FunctionComponent<{}> = () => {
  let { productId } = useParams();
  
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ height: "auto", marginTop: "9.375rem", position: "relative", width: "100%" }}>
      <ProductDetails productId={productId as string} />
    </div>
  );
};
