import { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  IconButton,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

import { useModal } from "../context/ModalContext";
import { ImageUploader } from "../ImageUploader";
import { InventoryItemForm } from "../../utils";
import { AutocompleteSelectBox } from "../AutocompleteSelectBox";
import {
  getCategoriesCollection,
  getModelsCollection,
  getSeriesCollection,
  getSubcategoriesCollection,
} from "../../api";
import { surCorTheme } from "../../styles";
import { NumericFormatCustom } from "../NumericFormatCustom";
import { FeatureComboBox } from "../FeatureComboBox";

const MainContentContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "0.5fr 1fr 1fr",
  gridTemplateRows: "1fr",
  gridColumnGap: "0.75rem",
});

const FormContainer = styled("div")({
  width: "29rem",
});

const DisplayNameTypography = styled("h1")({
  color: `${surCorTheme.palette.secondary.main} !important`,
  fontFamily: `${surCorTheme.typography.fontFamily} !important`,
  fontSize: "2.5rem",
  fontWeight: `${600} !important`,
  marginBottom: "1.5rem",
});

export const AddToInventoryModal: FC<{}> = () => {
  const { t } = useTranslation("global");
  const { closeModal, modalState } = useModal();

  const [lists, setLists] = useState<any>({
    categories: [],
    subcategories: [],
    series: [],
    models: [],
  });

  const { control, handleSubmit, resetField, setValue, watch } =
    useForm<InventoryItemForm>({
      defaultValues: {
        thumbnail: "",
        imageUrls: [],
        id: '',
        name: "Samsung",
        category: "",
        model: "",
        series: "",
        subcategory: "",
        price: 0,
        features: [],
        techSpecs: [],
      },
    });

  useEffect(() => {
    const getCollections = async () => {
      let cat, subcat, srs, mdls;

      cat = await getCategoriesCollection();
      subcat = await getSubcategoriesCollection();
      srs = await getSeriesCollection();
      mdls = await getModelsCollection();

      setLists({
        categories: cat,
        subcategories: subcat,
        series: srs,
        models: mdls,
      });
    };
    getCollections();
  }, []);

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <Dialog open={modalState.open} onClose={closeModal}>
      <DialogTitle>
        {t("modalTitles.addInventoryItemModalTitle")}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <MainContentContainer>
            <Controller
              name="thumbnail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ImageUploader images={value} onChange={onChange} />
              )}
            />
            <FormContainer>
              <DisplayNameTypography>{watch("name")}</DisplayNameTypography>
              <Controller
                name="price"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    margin="dense"
                    id="price"
                    label={t("fieldNames.price")}
                    onChange={onChange}
                    sx={{
                      fieldset: { border: "0.063rem #000 solid" },
                    }}
                    type="tel"
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    variant="outlined"
                    value={value}
                  />
                )}
              />
              <Grid
                alignItems={"center"}
                container
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ marginTop: "0.5rem" }}
              >
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: "14rem" }}>
                      <AutocompleteSelectBox
                        disabled={false}
                        field={field}
                        id={"category"}
                        label={t("fieldNames.category")}
                        options={lists.categories}
                        optionFormat={(option) =>
                          option ? t(`categoryOptionLabels.${option}`) : ""
                        }
                        onChange={(event, value) => {
                          resetField("subcategory", { defaultValue: "" });
                          resetField("series", { defaultValue: "" });
                          resetField("model", { defaultValue: "" });

                          setValue("category", value);
                        }}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="subcategory"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: "14rem" }}>
                      <AutocompleteSelectBox
                        disabled={watch("category") === ""}
                        field={field}
                        id={"subcategory"}
                        label={t("fieldNames.subcategory")}
                        options={lists.subcategories.filter(
                          (option: any) => option.parent === watch("category")
                        )}
                        optionFormat={option => option ? t(`subcategoryOptionLabels.${option}`) : ""}
                        onChange={(event, value) => {
                          resetField("series", { defaultValue: "" });
                          resetField("model", { defaultValue: "" });

                          setValue("subcategory", value);
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid
                container
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Controller
                  name="series"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: "14rem" }}>
                      <AutocompleteSelectBox
                        disabled={watch("subcategory") === ""}
                        field={field}
                        id={"series"}
                        label={t("fieldNames.series")}
                        options={lists.series.filter(
                          (option: any) =>
                            option.parent === watch("subcategory")
                        )}
                        optionFormat={(option) =>
                          option ? t(`seriesOptionLabels.${option}`) : ""
                        }
                        onChange={(event, value) => {
                          resetField("model", { defaultValue: "" });

                          setValue("series", value);
                          setValue("name", `Samsung ${t(`seriesOptionLabels.${watch("series")}`)}`);
                        }}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="model"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: "14rem" }}>
                      <TextField
                        disabled={watch("series") === ""}
                        fullWidth
                        margin="dense"
                        id="model"
                        label={t("fieldNames.model")}
                        onChange={(event) => {
                          setValue("model", event.target.value);
                          setValue('id', `${watch("series")}${watch("model")}`.replace(/\s/g, ''));
                          setValue("name", `Samsung ${t(`seriesOptionLabels.${watch("series")}`)} ${watch("model")}`);
                        }}
                        sx={{
                          fieldset: { border: "0.063rem #000 solid" },
                        }}
                        type="tel"
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </FormContainer>
            <Grid>
              <Controller
                  name="model"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: "14rem" }}>
                      <FeatureComboBox
                        field={field}
                        onChange={(event, value) => {
                          setValue("features", value);
                        }}
                      />
                    </FormControl>
                  )}
              />
            </Grid>
          </MainContentContainer>
        </DialogContent>
        <DialogActions>
          <Button type={"submit"}>Crear</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
