import { FC, useCallback, useEffect, useState } from "react";

import {
  Button,
  CardMedia,
  Typography
} from "@mui/material";

import {
  AttachMoney,
  ShoppingCart
} from "@mui/icons-material";

import ImageViewer from "react-simple-image-viewer";

import PageSection from "../components/PageSection";
import { ProductSlider } from "../components/ProductSlider";
import { ProductSummary } from "../components/ProductSummary";

import { surCorTheme, styles } from "../styles";
import {
  SamsungProduct,
  ExampleProductList,
  numberWithCommas,
} from "../utils";
import { TechSpecsTable } from "./TechSpecsTable";
import { FeatureDisplay } from "./FeatureDisplay";
import { getProductById } from "../api";
import { useGlobal } from "./context/GlobalContext";
import { Loader } from "./Loader";

interface ProductDetailsProps {
  productId: string;
}

interface ProductDetailsViewerProps {
  product: SamsungProduct;
  openImageViewer: (index: any) => void;
  addProductToCart: (product: SamsungProduct) => void;
}

const MainContainerStyle = (product: any) => {
  return {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexGrow: 1,
    height: product ? 'auto' : '100vh',
    justifyContent: 'space-between',
    marginTop: '9.375rem',
    position: 'relative' as 'relative',
    width: '100vw',
  };
};

const ProductDetailsViewerFirstHalfContainerStyle = {
  display: "flex",
  height: "auto",
  justifyContent: "space-evenly",
  minWidth: "80rem",
};

const CardMediaStyle = {
  cursor: "pointer",
  height: "400px!important",
  width: "400px!important",
};

const ProductDetailsViewer: FC<ProductDetailsViewerProps> = ({
  addProductToCart,
  openImageViewer,
  product
}) => {

  const checkout = () => {
    // Create PaymentIntent as soon as the page loads
    fetch("http://localhost:4000/checkout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ stripeId: product.stripeId, quantity: 1 }] }),
    })
      .then((res) => { return res.json(); })
      .then((res) => { if(res.url) window.location.assign(res.url); });
  };

  return (
    <>
      <div style={ProductDetailsViewerFirstHalfContainerStyle}>
        <CardMedia
          alt="product-img"
          component="img"
          onClick={() => openImageViewer(0)}
          sx={CardMediaStyle}
          image={product ? product.thumbnail : ""}
        />
        <div style={{ minWidth: "30rem" }}>
          <Typography sx={styles.sectionTitle}>{product?.name}</Typography>
          <Typography sx={styles.productPrice}>{`$ ${numberWithCommas(product.price)}`}</Typography>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              height: "auto",
            }}
          >
            <Button
              onClick={checkout}
              sx={{
                backgroundColor: surCorTheme.palette.primary.main,
                fontFamily: "SamsungOne",
                fontSize: "1rem",
                fontWeight: 600,
                padding: "0.813rem",
                textTransform: "capitalize",
                width: "12.75rem",
                "&:hover": {
                  backgroundColor: "#a1a1a1",
                },
              }}
              variant={"contained"}
              startIcon={<AttachMoney />}
            >
              ¡Comprar ahora!
            </Button>
            <Button
              sx={{
                backgroundColor: surCorTheme.palette.secondary.main,
                fontFamily: "SamsungOne",
                fontSize: "1rem",
                fontWeight: 600,
                padding: "0.813rem",
                textTransform: "capitalize",
                width: "12.75rem",
                "&:hover": {
                  backgroundColor: "#a1a1a1",
                },
              }}
              onClick={() => {
                addProductToCart(product);
              }}
              variant={"contained"}
              startIcon={<ShoppingCart />}
            >
              Agregar al carrito
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridColumnGap: "1em",
          height: "auto",
          margin: "2.25rem 0",
          minHeight: "5rem",
          minWidth: "60rem",
        }}
      >
        {product.features &&
          product.features.map((feature: any) => (
            <FeatureDisplay feature={feature} />
          ))}
      </div>
      <TechSpecsTable product={product} />
      <PageSection title={"Tambien te puede interesar..."}>
        <ProductSlider>
          {ExampleProductList.map((product) => (
            <ProductSummary product={product} />
          ))}
        </ProductSlider>
      </PageSection>
    </>
  );
};

export const ProductDetails: FC<ProductDetailsProps> = ({ productId }) => {
  const { addProductToCart } = useGlobal();
  const [product, setProduct] = useState<SamsungProduct | null>(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [open, setOpen] = useState(true);

  const handleBackdropClose = () => {
    setOpen(false);
  };

  const fetchProductData = useCallback(async () => {
    const prod = await getProductById(productId);
    setProduct(prod);

    setOpen(false);
  }, [productId]);

  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div style={MainContainerStyle(product)}>
      {isViewerOpen && (
        <ImageViewer
          src={[product ? product.thumbnail : ""]}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
      {product ? (
        <ProductDetailsViewer
          addProductToCart={addProductToCart}
          openImageViewer={openImageViewer}
          product={product}
        />
      ) : (
        <Loader open={open} handleBackdropClose={handleBackdropClose}/>
      )}
    </div>
  );
};
