import { FC } from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/system";
import { Typography } from "@mui/material";

import PageSection from "../components/PageSection";
import { ProductSlider } from "../components/ProductSlider";
import { ProductSummary } from "../components/ProductSummary";

import { surCorTheme } from "../styles";

import { ExampleProductList } from "../utils";

/**
 * Properties for the component @CheckoutPageSuccess
 */

const PageContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  height: "auto",
  justifyContent: "center",
  marginTop: "7.938rem",
  position: "relative",
  width: "100%",
});

const LegendContainer = styled("div")({
  backgroundColor: "#61d049",
  marginBottom: "1rem",
  padding: "1.5rem 5vw",
  width: "100vw",
});

/**
 * 
 * @returns A component to show the 
 */
export const CheckoutPageSuccess: FC<{}> = () => {
  const { t } = useTranslation("global");

  return (
    <PageContainer>
      <LegendContainer>
        <Typography sx={{ color: '#fff', fontFamily: surCorTheme.typography.fontFamily, fontSize: '3rem', fontWeight: 700 }}>
          {t("translations.successfulTransaction")}
        </Typography>
        <Typography sx={{ color: '#fff', fontFamily: surCorTheme.typography.fontFamily, fontSize: '1rem', fontWeight: 400 }}>
          {t("translations.purchaseSuccessMessage")}
        </Typography>
      </LegendContainer>

      <PageSection title={t("translations.orderSummary")}>
      </PageSection>

      <PageSection title={"Tambien puede interesarte..."}>
        <ProductSlider>
          {ExampleProductList.map((product, key) => (
            <ProductSummary product={product} key={key} />
          ))}
        </ProductSlider>
      </PageSection>
    </PageContainer>
  );
};
