import { FC, useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CATEGORIES, TAX_PERCENTAGE, numberWithCommas } from "../utils";
import { getInventoryItems } from "../api";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 700
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


interface InventoryItem {
  name: string;
  price: number;
  category: string;
}


export const InventoryTable: FC<{}> = () => {
  const [rows, setRows] = useState<InventoryItem[] | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  const getInventory = useCallback(async () => {
    const productList = await getInventoryItems();
    setRows(productList);
  }, []);

  useEffect(() => {
    getInventory();
  }, [getInventory]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Producto</StyledTableCell>
                <StyledTableCell align="right">Precio unitario (MXN)</StyledTableCell>
                <StyledTableCell align="right">Impuesto aplicado (MXN)</StyledTableCell>
                <StyledTableCell align="right">Categoría</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {rows?.map((row: InventoryItem) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">{ row.name }</StyledTableCell>
                <StyledTableCell align="right">{`$${numberWithCommas(row.price)} MXN`}</StyledTableCell>
                <StyledTableCell align="right">{`$${numberWithCommas(row.price * TAX_PERCENTAGE)} MXN`}</StyledTableCell>
                <StyledTableCell align="right">{CATEGORIES[row.category]}</StyledTableCell>
              </StyledTableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
