import { ActionTypes, GlobalState, SamsungOrderItem, getCartTotalPrice } from "../../utils";

export const globalReducer = (state: GlobalState, action: any): GlobalState => {
  switch (action.type) {
    case ActionTypes.STARTUP: {
      return {
        ...state,
        featuredProds: action.featuredProdList,
        sliderImages: action.sliderImages,
      };
    }
    case ActionTypes.ADD_PRODUCT_TO_CART: {
      const findIndex = state.cart.items.findIndex((i: SamsungOrderItem) => i.id === action.newProduct.id);
      const newCartItems: SamsungOrderItem[] = [ ...state.cart.items ];

      if (findIndex !== -1) newCartItems[findIndex].quantity += 1;
      else {
        const item: SamsungOrderItem = {
          id: action.newProduct.id,
          name: action.newProduct.name,
          quantity: 1,
          unitPrice: action.newProduct.price,
          thumbnail: action.newProduct.imageUrls.thumbnail,
        };
        newCartItems.push(item);
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          items: [...newCartItems],
          totalPrice: getCartTotalPrice(newCartItems),
        },
      };
    }
    case  ActionTypes.CHANGE_PRODUCT_QUANTITY: {
      const newCartItems: SamsungOrderItem[] = [
        ...state.cart.items
      ];
      const findIndex = newCartItems.findIndex(
        (i: SamsungOrderItem) => i.id === action.newProduct.id
      );

      if (findIndex !== -1) newCartItems[findIndex].quantity = action.quantity;
      return {
        ...state,
        cart: {
          ...state.cart,
          items: [...newCartItems],
          totalPrice: getCartTotalPrice(newCartItems),
        },
      };
    }
    case  ActionTypes.DELETE_PRODUCT: {
      const newCartItems: SamsungOrderItem[] = state.cart.items.filter(
        (item) => item.id !== action.newProduct.id
      );
      return {
        ...state,
        cart: {
          ...state.cart,
          items: [...newCartItems],
          totalPrice: getCartTotalPrice(newCartItems),
        },
      };
    }
    case  ActionTypes.EMPTY_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          items: [],
          totalPrice: 0.0,
        },
      };
    }
    default:
      throw Error("Unknown action: " + action.type);
  }
};
