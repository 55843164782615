import { Fragment, FC } from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

interface FeatureComboBoxProps {
  field: any;
  onChange: (event: any, value: any) => void;
}

export const FeatureComboBox: FC<FeatureComboBoxProps> = ({
  field,
  onChange
}) => {
  return (
    <Fragment>
      <Autocomplete
        value={field.value}
        onChange={onChange}
        multiple
        id="feature-combo-box"
        options={[]}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Users"
            placeholder="Search"
          />
        )}
      />
    </Fragment>
  );
};
