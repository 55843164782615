import React from "react";

import { ImageSlider } from "../components/ImageSlider";

import PageSection from "../components/PageSection";
import { ProductSlider } from "../components/ProductSlider";
import { ProductSummary } from "../components/ProductSummary";

import { ExampleProductList } from "../utils";
import { useGlobal } from "../components/context/GlobalContext";

export const LandingPage: React.FunctionComponent<{}> = () => {
  const { state } = useGlobal();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "auto",
        justifyContent: "center",
        marginTop: "9.375rem",
        position: "relative",
        width: "100%",
      }}
    >
      <ImageSlider images={state.sliderImages} />
      <PageSection title={"Ofertas de la semana"}>
        <ProductSlider>
          {ExampleProductList.map((product, key) => (
            <ProductSummary product={product} key={key} />
          ))}
        </ProductSlider>
      </PageSection>
      <PageSection title={"Productos destacados"}>
        <ProductSlider>
          {state.featuredProds.map((product: any, key: number) => (
            <ProductSummary product={product} key={key} />
          ))}
        </ProductSlider>
      </PageSection>
      <PageSection title={"#SurCor"}>
        <div
          style={{
            alignItems: "flex-start",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "3.125rem",
            maxWidth: "78.125rem",
          }}
        >
          <iframe
            src={process.env.REACT_APP_FB_IFRAME_URL}
            width="500"
            height="600"
            style={{border: 'none', overflow: 'hidden' }}
            allowFullScreen={true}
            title='surcor-feed'
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </PageSection>
    </div>
  );
};
