import Typography from "@mui/material/Typography";
import React from "react";

import { styles } from "../styles";

export const ReadingSection: React.FunctionComponent<{
  title: string;
  content: string;
  side?: boolean;
}> = ({ title, content, side }) => {
  return (
    <div style={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      width: "100%",
    }}>
      {!side && (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {'Image goes here!'}
        </div>
      )}
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0 2rem",
        }}
      >
        <Typography sx={styles.contentTitle} variant="h3">
          {title}
        </Typography>
        <Typography sx={styles.contentText}>{content}</Typography>
      </div>
      {side && (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {'Picture goes here!'}
        </div>
      )}
    </div>
  );
};
