import { Category, DrawerOption, SubCategory } from "./types";
import { SamsungProduct } from "./props";
import data from "../json/info.json";

export const TAX_PERCENTAGE = 0.16;

export const CATEGORIES: { [index: string]: string } = {
  mobile: "Móvil",
  tv_audio: "TV y Audio",
  appliances: "Linea blanca",
};

export const WhoWeAre = {
  title: "¿Quienes somos?",
  content: `Somos una empresa dedicada a la distribución de productos tecnológicos de la marca Samsung.\
  Somos una empresa dedicada a la tecnología para oficinas, con el
  objetivo de darle a los nigropetenses, las herramientas necesarias
  para modernizar sus hogares y negocios con los productos de la más
  alta calidad. De la mano de Samsung, nuestro catálogo le ofrece las
  mejores opciones en el mercado para su hogar, oficina y productividad.`,
};

export const OurMission = {
  title: "Nuestra misión",
  content: `Somos una empresa dedicada a la distribución de productos tecnológicos de la marca Samsung.\
  Somos una empresa dedicada a la tecnología para oficinas, con el
  objetivo de darle a los nigropetenses, las herramientas necesarias
  para modernizar sus hogares y negocios con los productos de la más
  alta calidad. De la mano de Samsung, nuestro catálogo le ofrece las
  mejores opciones en el mercado para`,
};

export const OurVision = {
  title: "Nuestra visión",
  content: `Somos una empresa dedicada a la distribución de productos tecnológicos de la marca Samsung.\
  Somos una empresa dedicada a la tecnología para oficinas, con el
  objetivo de darle a los nigropetenses, las herramientas necesarias
  para modernizar sus hogares y negocios con los productos de la más
  alta calidad. De la mano de Samsung, nuestro catálogo le ofrece las
  mejores opciones en el mercado para`,
};

export const emptyCategory: Category = {
  drawerImageUrl: "",
  name: "",
  value: "",
  subcategories: [
    {
      name: "",
      series: [""],
    },
  ],
};

export const emptySubCategory: SubCategory = {
  name: "",
  series: [""],
};

export const contactDrawerOptions: DrawerOption[] = [
  {
    key: 0,
    link: () => window.open(data.facebook),
    text: "Facebook",
  },
  {
    key: 1,
    link: () =>
      window.open(
        `https://web.whatsapp.com/send?phone=${data.whatsapp}&text=${encodeURI(
          data.whatsappStartMessage
        )}&app_absent=0`
      ),
    text: "WhatsApp",
  },
  {
    key: 2,
    link: () => window.open(data.instagram),
    text: "Instagram",
  },
];

export const userDrawerOptions: DrawerOption[] = [
  {
    key: 0,
    text: "Iniciar sesión",
  },
  {
    key: 1,
    text: "Registrate",
  },
];

export const ExampleProduct: SamsungProduct = {
  id: "sga12-00000-00001",
  model: "12",
  series: "Galaxy A",
  name: "Samsung Galaxy A12",
  inStock: 8,
  price: 4419,
  stripeId: "",
  dimensions: {
    height: 164,
    width: 8.9,
    length: 75.8,
    weight: 205,
  },
  colors: ["#000000", "#ff0000", "#ffffff", "#0098C1"],
  category: "mobile",
  subcategory: "smartphones",
  features: {
    bluetooth: {
      name: "Bluetooth",
      value: "v5.0 LE, A2DP",
      iconName: "bluetooth",
    },
    ram: {
      name: "RAM",
      value: "2GB - 6GB",
      iconName: "memory",
    },
    os: {
      name: "Sistema",
      value: "Android 10",
      iconName: "android",
    },
    storage: {
      name: "Almacenamiento",
      value: "32 GB - 128 GB",
      iconName: "sd_card",
    },
  },
  techSpecs: {
    battery: {
      name: "Bateria",
      description: "Li-Po 5000",
    },
    resolution: {
      name: "Resolución",
      description: "700x1600px",
    },
    os: {
      name: "Sistema Operativo",
      description: "Android 10",
    },
    cpu: {
      name: "CPU",
      description: "OctaCore (2.3GHz + 1.8GHz)",
    },
  },

  thumbnail: "",
  imageUrls: [],
};

export const EmptyProduct: SamsungProduct = {
  id: "",
  model: "",
  series: "",
  name: "",
  inStock: 0,
  price: 0,
  stripeId: "",
  dimensions: {
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
  },
  colors: ["#000000"],
  category: "",
  subcategory: "",
  features: {},
  techSpecs: {},
  thumbnail: "",
  imageUrls: [],
};

export const ExampleProductList: SamsungProduct[] = [
  {
    id: "sga12-00000-00001",
    stripeId: "",
    model: "12",
    series: "Galaxy A",
    name: "Samsung Galaxy A12",
    inStock: 8,
    price: 4419,
    dimensions: {
      height: 164,
      width: 8.9,
      length: 75.8,
      weight: 205,
    },
    colors: ["#000000"],
    category: "mobile",
    subcategory: "smartphones",
    features: {
      bluetooth: {
        name: "Bluetooth",
        value: "v5.0 LE, A2DP",
        iconName: "bluetooth",
      },
    },
    techSpecs: {
      battery: {
        name: "Bateria",
        description: "Li-Po 5000",
      },
      resolution: {
        name: "Resolución",
        description: "700x1600px",
      },
      os: {
        name: "Sistema Operativo",
        description: "Android 10",
      },
      cpu: {
        name: "CPU",
        description: "Algo",
      },
    },
    thumbnail: "",
    imageUrls: [],
  },
  {
    id: "sga12-00000-00001",
    stripeId: "",
    model: "12",
    series: "Galaxy A",
    name: "Samsung Galaxy A12",
    inStock: 8,
    price: 4419,
    dimensions: {
      height: 164,
      width: 8.9,
      length: 75.8,
      weight: 205,
    },
    colors: ["#000000"],
    category: "mobile",
    subcategory: "smartphones",
    features: {
      bluetooth: {
        name: "Bluetooth",
        value: "v5.0 LE, A2DP",
        iconName: "bluetooth",
      },
    },
    techSpecs: {
      battery: {
        name: "Bateria",
        description: "Li-Po 5000",
      },
      resolution: {
        name: "Resolución",
        description: "700x1600px",
      },
      os: {
        name: "Sistema Operativo",
        description: "Android 10",
      },
      cpu: {
        name: "CPU",
        description: "Algo",
      },
    },
    thumbnail: "",
    imageUrls: [],
  },
  {
    id: "sga12-00000-00001",
    stripeId: "",
    model: "12",
    series: "Galaxy A",
    name: "Samsung Galaxy A12",
    inStock: 8,
    price: 4419,
    dimensions: {
      height: 164,
      width: 8.9,
      length: 75.8,
      weight: 205,
    },
    colors: ["#000000"],
    category: "mobile",
    subcategory: "smartphones",
    features: {
      bluetooth: {
        name: "Bluetooth",
        value: "v5.0 LE, A2DP",
        iconName: "bluetooth",
      },
    },
    techSpecs: {
      battery: {
        name: "Bateria",
        description: "Li-Po 5000",
      },
      resolution: {
        name: "Resolución",
        description: "700x1600px",
      },
      os: {
        name: "Sistema Operativo",
        description: "Android 10",
      },
      cpu: {
        name: "CPU",
        description: "Algo",
      },
    },
    thumbnail: "",
    imageUrls: [],
  },
  {
    id: "sga12-00000-00001",
    stripeId: "",
    model: "12",
    series: "Galaxy A",
    name: "Samsung Galaxy A12",
    inStock: 8,
    price: 4419,
    dimensions: {
      height: 164,
      width: 8.9,
      length: 75.8,
      weight: 205,
    },
    colors: ["#000000"],
    category: "mobile",
    subcategory: "smartphones",
    features: {
      bluetooth: {
        name: "Bluetooth",
        value: "v5.0 LE, A2DP",
        iconName: "bluetooth",
      },
    },
    techSpecs: {
      battery: {
        name: "Bateria",
        description: "Li-Po 5000",
      },
      resolution: {
        name: "Resolución",
        description: "700x1600px",
      },
      os: {
        name: "Sistema Operativo",
        description: "Android 10",
      },
      cpu: {
        name: "CPU",
        description: "Algo",
      },
    },
    thumbnail: "",
    imageUrls: [],
  },
  {
    id: "sga12-00000-00001",
    stripeId: "",
    model: "12",
    series: "Galaxy A",
    name: "Samsung Galaxy A12",
    inStock: 8,
    price: 4419,
    dimensions: {
      height: 164,
      width: 8.9,
      length: 75.8,
      weight: 205,
    },
    colors: ["#000000"],
    category: "mobile",
    subcategory: "smartphones",
    features: {
      bluetooth: {
        name: "Bluetooth",
        value: "v5.0 LE, A2DP",
        iconName: "bluetooth",
      },
    },
    techSpecs: {
      battery: {
        name: "Bateria",
        description: "Li-Po 5000",
      },
      resolution: {
        name: "Resolución",
        description: "700x1600px",
      },
      os: {
        name: "Sistema Operativo",
        description: "Android 10",
      },
      cpu: {
        name: "CPU",
        description: "Algo",
      },
    },
    thumbnail: "",
    imageUrls: [],
  },
];
