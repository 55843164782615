import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 700
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 'none'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#D6D6D6',
  }
}));

export const TechSpecsTable: React.FC<{ product: any }> = ({ product }) => {
    return (
        <Table sx={{ maxWidth: 1000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Componente</StyledTableCell>
              <StyledTableCell align="right">Descripción</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {product.techSpecs.map((spec: any) => (
              <StyledTableRow key={spec.name}>
                <StyledTableCell component="th" scope="row">{spec.name}</StyledTableCell>
                <StyledTableCell align="right">{spec.description}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
    );
  }